// Dependencies
import React, { useState, useEffect, useRef, useCallback } from 'react';
// Hooks
import useTouch from "../hooks/useTouch";

const Slide = ({ slideIndex, currentSlideIndex, setRef, children }) => {

  const isActive = slideIndex !== undefined && (slideIndex === currentSlideIndex);

  return (
    <div ref={isActive ? setRef : null} className={`swiper-slide ${isActive ? 'active' : ''}`}>
      {children}
    </div>
  )
}

const Swiper = ({ className, id, scrollMultiplier, children }) => {

  const [isTouch, canRender] = useTouch();

  // Set ref on current slide...
  const currentSlide = useRef();
  const container = useRef();
  const wrapper = useRef();

  const [isLeftHandSide, setSide] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleMouseMove = e => {
    const isLeft = e.clientX < (window.innerWidth * 0.5);
    if (isLeft && !isLeftHandSide) {
      setSide(true);
    } else if (!isLeft && isLeftHandSide) {
      setSide(false);
    }
  }

  const handleClick = () => {
    if (!isTouch) {
      if (isLeftHandSide) {
        setCurrentSlideIndex(prev => prev === 0 ? children.length - 1 : prev - 1);
      } else {
        setCurrentSlideIndex(prev => (prev === children.length - 1) ? 0 : prev + 1);
      }
    }
  }

  const onSlideChange = useCallback(() => {
    if (canRender) {
      const multiplier = scrollMultiplier || (currentSlide.current ? (currentSlide.current.offsetWidth / window.innerWidth) : scrollMultiplier);
      const amountToScroll = Math.round((currentSlideIndex + 1) * (container.current.offsetWidth * multiplier));

      // ON LOOP
      if (currentSlideIndex === 0) {
        if (!isTouch) {
          wrapper.current.scrollLeft = Math.round(container.current.offsetWidth * multiplier);
        }
      } else {
        wrapper.current.scrollLeft = amountToScroll;
      }
    }
  }, [currentSlideIndex, canRender, isTouch, scrollMultiplier])

  useEffect(onSlideChange, [currentSlideIndex, canRender])

  return (
    canRender && (
      <div className={className}>
        <div
          ref={container}
          className={`swiper-container ${isLeftHandSide ? 'larr' : 'rarr'}`}
          onClick={!isTouch ? handleClick : null}
          onMouseMove={handleMouseMove}
        >
          <div className="swiper-wrapper" ref={wrapper}>
            <Slide key={`${id}-last-slide-loop`}>
              {children[children.length - 1]}
            </Slide>

            {React.Children.map(children, (child, index) => {
              return (
                <Slide
                  slideIndex={index}
                  onSlideUpdate={(slideIndex) => setCurrentSlideIndex(slideIndex)}
                  currentSlideIndex={currentSlideIndex} key={`${id}-slide-${index}`}
                  setRef={currentSlide}
                >
                  {child}
                </Slide>
              )
            })}

            <Slide key={`${id}-first-slide-loop`}>
              {children[0]}
            </Slide>
          </div>
        </div>
      </div>
    )
  )
}

export default Swiper
